import React from "react";

const Privacy = ({ getTranslatedText }) => {
  return (
    <div>
      <section className="privacy">
        <div className="privacy__header"></div>
        <div className="privacy__container">
          <h1 className="privacy__title">
            {getTranslatedText("Privacy Heading")}
          </h1>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingText")}
          </p>
          <h3 className="privacy__heading">
            {getTranslatedText("Privacy HeadingPolicy")}
          </h3>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingPolicyText1")}
            <a href="https://creditper.pk/">www.creditper.pk</a>
            {getTranslatedText("Privacy HeadingPolicyText2")}
          </p>
          <h3 className="privacy__heading">
            {getTranslatedText("Privacy HeadingInfo")}
          </h3>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingInfoText1")}
          </p>
          <h3 className="privacy__heading">
            {getTranslatedText("Privacy HeadingInfo2")}
          </h3>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingInfo2Text1")}
          </p>
          <h4>{getTranslatedText("Privacy HeadingInfo2Text2")}</h4>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingInfo2Text3")}
          </p>
          <h4>{getTranslatedText("Privacy HeadingInfo2Text4")}</h4>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingInfo2Text5")}
          </p>
          <h4>{getTranslatedText("Privacy HeadingInfo2Text6")}</h4>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingInfo2Text7")}
          </p>
          <h3 className="privacy__heading">
            {getTranslatedText("Privacy HeadingCookies")}
          </h3>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingCookiesText1")}
          </p>
          <h3 className="privacy__heading">
            {getTranslatedText("Privacy HeadingUses")}
          </h3>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingUsesText1")}
          </p>
          <h3 className="privacy__heading">
            {getTranslatedText("Privacy HeadingDisclosure")}
          </h3>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingDisclosureText1")}
          </p>
          <h3 className="privacy__heading">
            {getTranslatedText("Privacy HeadingStores")}
          </h3>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingStoresText1")}
          </p>
          <h3 className="privacy__heading">
            {getTranslatedText("Privacy HeadingRights")}
          </h3>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingRightsText1")}&nbsp;
            <a href="mailto:hello@creditper.pk">hello@creditper.pk</a>
          </p>
          <h3 className="privacy__heading">
            {getTranslatedText("Privacy HeadingPolicy")}
          </h3>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingPolicyText1")}
          </p>
          <h3 className="privacy__heading">
            {getTranslatedText("Privacy HeadingContact")}
          </h3>
          <p className="privacy__text">
            {getTranslatedText("Privacy HeadingContactText1")} &nbsp;
            <a href="mailto:hello@creditper.pk">hello@creditper.pk</a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Privacy;
