import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import Privacy from "../../components/Privacy";
import Footer from "../../components/Footer";
import WhatsappChat from "../../components/WhatsappChat";
import { localStorageKeys } from "../../utility/localstorage";
import { getTranslatedText } from "../../utility/constant";

const PrivacyPolicy = () => {
  const [myCustomAppState, setMyCustomAppState] = useState({
    language: "English",
  });

  useEffect(() => {
    setMyCustomAppState({
      language: localStorage.getItem(localStorageKeys.language) || "English",
    });
  }, []);

  return (
    <Wrapper
      myCustomAppState={myCustomAppState}
      setMyCustomAppState={setMyCustomAppState}
    >
      <Privacy
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <Footer
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <WhatsappChat />
    </Wrapper>
  );
};

export default PrivacyPolicy;
